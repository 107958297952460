<template>
	<div class="cbox">

		<div style="" class="cxbox">

			<div class="title">录取查询</div>

			<div>
				<el-form ref="form" :model="EditItem">

					<el-row>
						<el-col :span="24">
							<el-form-item>

								<el-input v-model="EditItem.ksh" prefix-icon="el-icon-user" placeholder="请输入考生号"></el-input>

							</el-form-item>

						</el-col>

						<el-col :span="24">
							<el-form-item>

								<el-input v-model="EditItem.idcard" prefix-icon="el-icon-postcard"
									placeholder="请输入身份证号码"></el-input>

							</el-form-item>

						</el-col>


						<el-col :span="12">
							<el-form-item>

								<el-input v-model="EditItem.yzm" prefix-icon="el-icon-watch"
									placeholder="请输入验证码"></el-input>

							</el-form-item>

						</el-col>

						<el-col :span="12">
							<el-form-item>

								<div style="margin-top: -10px;" v-html="yzm.data"></div>

							</el-form-item>

						</el-col>


						<el-col :span="24">
							<el-form-item style="text-align: center; padding-top: 20px;">

								<el-button type="primary" style="width: 100%;background-color: #6f95f8;"
									@click="search">查询</el-button>
							
							</el-form-item>

						</el-col>



					</el-row>



				</el-form>
			</div>

		</div>
		
		
		<el-dialog title="录取结果" :visible.sync="dialogVisible" :close-on-click-modal="false" width="90%">
			<div slot="title">
				<div style="font-size: 18px; font-weight: bolder; border-bottom: 1px solid #E2E4ED; padding-bottom: 20px; text-align: center;">
					
					录取结果
				</div>
				
			</div>
			<!-- <div style="font-size: 16px;">
				<div><span>姓名：</span><span style="font-weight: bolder;">{{ resultData.stu_name }}</span></div>
				<div><span>准考证号：</span><span style="font-weight: bolder; ">{{ resultData.ksh }}</span></div>
				<div><span>身份证号：</span><span style="font-weight: bolder;">{{ resultData.idcard }}</span></div>
			</div> -->
			
			<table class="dtable" style="font-size: 16px; margin-top: 0px;">
				<tr>
					<td style="background-color: #EDF1FD; padding:10px 0; width: 90px;">姓名</td>
					<td style="padding: 20px 0;">{{ resultData.stu_name }}</td>
					
					
				</tr>
			
				<tr>
					<td style="background-color: #EDF1FD; padding: 10px 0;">考生号</td>
					<td style="padding: 20px 0;">{{ resultData.ksh }}</td>
				</tr>
				<tr>
					<td style="background-color: #EDF1FD; padding: 10px 0;">身份证号</td>
					<td style="padding: 20px 0;">{{ resultData.idcard }}</td>
				</tr>
				<tr>
					<td style="background-color: #EDF1FD; padding: 10px 0;">录取专业</td>
					<td style="padding: 20px 0;">{{ resultData.zhuanye }}</td>
				</tr>
				<tr>
					<td style="background-color: #EDF1FD; padding: 10px 0;">EMS</td>
					<td style="padding: 20px 0;">{{ resultData.ems }}</td>
				</tr>
					
			</table>
			
			
			
		</el-dialog>
		
		




	</div>
</template>
  
<script>

export default {
	components: {

	},
	data() {
		return {
			dialogVisible: false,
			EditItem: {
				ksh: "",
				idcard: "",
				yzm: ""
			},

			resultData: {},
			yzm: {
				data: "",
				text: ""
			}

		};
	},

	mounted() {
		document.title="江苏第二师范学院录取查询"
		this.getYzm()
	},
	methods: {
		resetForm(){
			this.EditItem = {
				ksh: "",
				idcard: "",
				yzm: ""
			}
		},
		getYzm() {
			this.$http.post("/api/luqu_yzm").then(res => {
				this.yzm = res.data
			})
		},
		search() {
			if (!this.EditItem.ksh) {
				this.$message.error("请输入考生号")
				return
			}

			if (!this.EditItem.idcard) {
				this.$message.error("请输入身份证号码")
				return
			}else {
					var myreg =
						/(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
					if (!myreg.test(this.EditItem.idcard)) {
				
						this.$message.error("请输入正确的身份证号")
						return
					}
				}

			if (!this.EditItem.yzm) {
				this.$message.error("请输入验证码")
				return
			}

			if (this.EditItem.yzm.toLowerCase() != this.yzm.text.toLowerCase()) {
				this.$message.error("验证码不正确！")
				return
			}

			this.$http.post("/api/luqu_search", this.EditItem).then(res => {
				if (res.data && res.data.ksh) {
					this.resultData = res.data
					this.dialogVisible = true
				} else {
					this.$alert('未查询到录取结果！', '查询提示', {
						confirmButtonText: '确定',
						callback: action => {
							
						}
					});
				}
			})

		}

	}
}
</script>
  

<style scoped>
.title {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	padding-bottom: 40px;
}

.cxbox {
	height: 400px;
	margin: 0 auto;
	padding: 30px 10px;
}</style>